import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Edit } from '@material-ui/icons';
import { getToken, navigate } from '../utils/utils';
import { ErrorDialog, VASPList } from '../components/common';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import AuthenticatedPage from '../components/authenticated-page';

function BundleServiceVASPList() {
  const token = getToken();

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'Bundle Service']}
          breadcrumbLinks={['/', '/bundle-service-vasp-list']}
        />
        <VASPList
          token={token}
          actions={[
            {
              icon: Edit,
              onClick: (__, rowData) => {
                const parameter = { vasp_code: rowData.vasp_code, vasp_name: rowData.vasp_name };
                navigate('/bundle-service-vasp', { state: { parameter } });
              },
            },
          ]}
          onError={setError}
        />
        <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
      </AuthenticatedPage>
    </Layout>
  );
}

export default BundleServiceVASPList;
